export default function auth({ to, next, store }) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    console.log(
      "🚀 ~ auth ~ store.getters.isLoggedIn:",
      store.getters.isLoggedIn
    );
    if (store.getters.isLoggedIn) {
      if (
        to.matched
          .find((record) => record.meta.roles)
          .meta.roles?.includes(store.getters?.userType?.toLowerCase())
      ) {
        next();
        return;
      } else {
        next("/dashboard");
        return;
      }
    } else {
      next("/login?redirected_from=" + to.fullPath);
      return;
    }
  } else {
    next();
  }
}
