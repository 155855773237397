import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";
import VueAnimateOnScroll from "vue3-animate-onscroll";
import mitt from "mitt";
import vClickOutside from "click-outside-vue3";
import Clipboard from "v-clipboard";
import currencyFormat from "@/utils/formatCurrency";

// import "vue-toast-notification/dist/theme-sugar.css";
import "vue-toast-notification/dist/theme-bootstrap.css";
import "vue3-toastify/dist/index.css";
import "./index.css";
import "./registerServiceWorker";
import "./assets/tailwind.scss";

const emitter = mitt();
// ...
function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  decimalCount = Math.abs(decimalCount);
  decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

  const negativeSign = amount < 0 ? "-" : "";

  let i = parseInt(
    (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
  ).toString();
  let j = i.length > 3 ? i.length % 3 : 0;

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
    (decimalCount
      ? decimal +
        Math.abs(amount - i)
          .toFixed(decimalCount)
          .slice(2)
      : "")
  );
}

createApp(App)
  .use(store)
  .use(VueToast, {
    // One of the options
    position: "top",
    duration: 900000,
  })
  .use(router)
  .use(VueAnimateOnScroll)
  .use(Clipboard)
  .provide("formatMoney", formatMoney)
  .provide("currencyFormat", currencyFormat)
  .provide("emitter", emitter)
  .use(vClickOutside)
  .mount("#app");
