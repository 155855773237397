export default function currencyFormat(price, currency = "NGN") {
  let naira = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    currencyDisplay: "narrowSymbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return naira.format(price || 0);
}

export function numberFormat(price) {
  let naira = Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return naira.format(price || 0);
}
